.benefits {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-row-gap: 40px;
    grid-column-gap: 50px;
    padding: 0 50px;
}



@media only screen and (max-width: 550px){
    .benefits {
        display: flex;
       flex-direction: column;

       justify-content: center;
       align-items: center;
  
    }
}




.benefit  {

    display: flex;
    justify-content: center;
    align-items: center;
   
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p{

    max-width: 200px;

    }

    .headline {
        font-weight: 600;
        margin-top: 20px;
    }

    .subHeadline {

        font-weight: 300;
    }
    
    .benefit_icon {
        width: 100px;
        height: auto;
        justify-self: center;
    }

}


@media only screen and (max-width: 880px){
    .benefits {
        grid-template-columns: auto auto;
    }
}