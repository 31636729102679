.logo_img {
    width: 75px;
    height: auto;
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    p {
         margin: 0;
         margin-bottom: 7px;
    }
}

.claim {
    font-weight: 500;
}

.footer {
    padding-bottom: 30px;
    padding-top: 30px;
    background-color: black;
    color: white;
    font-size: 14px;
    text-align: left;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
        font-size: 24px;
        font-weight: 400;
    }
}

.footer p {
    text-align: left;
    margin: 0;
    margin-bottom: 5px;
}

.content {

    padding: 50px 0;
}

.flag_div img {
    
    width: 75px;
    height: auto;
    justify-self: center;
}

@media only screen and (max-width: 800px){
    .content {
        padding: 50px 0;
     
    }
}

a {

    text-decoration: none;
}
.flag_div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}


.flag_div_left, .flag_div_right {
    display: grid;
    border: 3px solid white;
    width: fit-content;
    padding: 20px 10px;
    margin: 0 20px;
    border-radius: 10px;
}


.flag_div_left:hover, .flag_div_right:hover {
    background-color: rgba(220, 220, 220, 0.3);
}


.country_button {
    background-color: #8FBF25;
    color: white;
    padding: 5px 10px;
    border-radius: 2px;
    width: 100px;
}

.header {
    height: 600px;
}


@media only screen and (max-width: 330px){
    .flag_div {
        flex-direction: column;
        a {
            margin-bottom: 20px;
        }
    }

    .header {
        height: auto;
        padding-top: 30px;
    }


    .footer {
        padding: 10px;
    }
}
